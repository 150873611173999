import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import ModalComponent from './ModalComponentRespuestas';
import withAuth from '../funtions/withAuth';
import { Helmet } from 'react-helmet';

import WebSocketComponent from '../components/WebSocketComponent';

const backendUrl = process.env.REACT_APP_BACKEND_URL

const RespuestaList = () => {
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState(false);
    const [urlExport, setUrlExport] = useState(false);

    const getUserIdFromToken = async (token) => {
        try {
            const response = await fetch(`${backendUrl}/company/api/get-user-id/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setUserId(data.user_id);
        } catch (error) {
            console.error('Error fetching user ID:', error.message);
        }
    };

    const fetchVideos = async (userId) => {
        const localUrl = window.location.href;
        const regex = /(\d+)(?!.*\d)/; // Busca el último grupo de dígitos en la URL
        const match = localUrl.match(regex);
        let extractedValue = 1;
        if (match) {
            extractedValue = match[1];
            console.log(extractedValue); // Debería imprimir el último número en la URL
        } else {
            console.log('No se encontró un número en la URL.');
        }
        const url = `https://storage.bunnycdn.com/trustreel/${userId}/${extractedValue}/`;
        const exportUrl = `https://TRUSTREEL.b-cdn.net/${userId}/${extractedValue}/`;
        setUrl(url);
        setUrlExport(exportUrl);

        try {
            const response = await fetch(`${backendUrl}/videos/videoslist`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'url': url,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch videos');
            }

            const files = await response.json();
            const videoFiles = files
                .sort((a, b) => new Date(b.DateCreated) - new Date(a.DateCreated)) // Ordenar por DateCreated
            console.log(videoFiles);
            setVideos(videoFiles);
        } catch (error) {
            console.error('Error fetching videos:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            getUserIdFromToken(token).then(() => {
                if (userId) {
                    fetchVideos(userId);
                }
            });
        } else {
            console.error('No token found in localStorage');
        }
    }, [userId]);

    return (
        <div className='ml-20 sm:ml-60 md:ml-60'>
            <Helmet>
                <title>Answers - Trustreel.AI</title>
            </Helmet>
            <WebSocketComponent/>
            <Sidebar /> 
            <div className="container mx-auto p-4">
                <table className="overflow-x-auto w-full">
                    <thead className="bg-[#f230aa] text-white">
                        <tr key={0}>
                            <th className="py-2 px-4 ">Conference ID Name</th>
                            <th className="py-2 px-4 hidden md:table-cell">Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr
                                className="cursor-pointer hover:bg-[#f230aa] hover:text-white text-[#f230aa] transition-colors duration-200"
                            >
                                <td className="py-2 px-4 border-b border-[#f230aa]">Loading list...</td>
                                <td className="py-2 px-4 border-b border-[#f230aa] hidden md:table-cell"></td>
                            </tr>
                        ) : (
                            videos.map(video => (
                                <tr
                                    key={video.id}
                                    onClick={() => setSelectedVideo(video)}
                                    className="cursor-pointer hover:bg-[#f230aa] hover:text-white text-[#f230aa] transition-colors duration-200"
                                >
                                    <td className="py-2 px-4 border-b border-[#f230aa]">{video.ObjectName}</td>
                                    <td className="py-2 px-4 border-b border-[#f230aa] hidden md:table-cell">{video.DateCreated}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>

                {selectedVideo && <ModalComponent videoName={selectedVideo.ObjectName} customeUrl={url} urlExport={urlExport} onClose={() => setSelectedVideo(null)} />}
            </div>
        </div>
    );
};

export default withAuth(RespuestaList);