// VideoModal.js
import React, { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SubtitleEditor from "./SubtitleEditor";

import { FaTimes } from 'react-icons/fa';


/*const replacePort = (url, oldPort, newPort) => {
    return url.replace(`:${oldPort}`, `:${newPort}`);
};*/
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const VideoModal = ({ onClose, customeUrl, videoName, urlExport }) => {
    const [items, setItems] = useState([]);
    //const updatedBackendUrl = replacePort(backendUrl, 8000, "");

    useEffect(() => {
        fetchVideos();
    }, [customeUrl]);

    useEffect(() => {
        console.log(items);
    }, [items]);

    const queueIdMatch = customeUrl.match(/\/(\d+)\/$/);
    const queueId = queueIdMatch ? queueIdMatch[1] : null;

    const [subtitleUrl, setSubtitleUrl] = useState(null);
    const fetchVideos = async (userId) => {
        const url = `${customeUrl}${videoName}/`;
        console.log(url);
        try {
            const response = await fetch(`${backendUrl}/videos/videoslist`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                    url: url,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch videos");
            }

            const files = await response.json();
            setItems(files);

            console.log("Files", files);

            // Buscar el archivo de subtítulos
            const subtitleFile = files.find(file => file.ObjectName === 'subtitles.srt');

            if (subtitleFile) {
                const subtitleUrl = `${urlbase}${videoName}/${subtitleFile.ObjectName}`;
                setSubtitleUrl(subtitleUrl);
                console.log('Subtitle URL:', subtitleUrl);
                // Puedes usar subtitleUrl aquí según sea necesario
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        } finally {
        }
    };

    const urlbase = urlExport.toLowerCase();

    const videoRefs = useRef([]);
    const [video_url, setVideoUrl] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    const playersRef = useRef([]);
    useEffect(() => {
        if (items && items.length > 0) {
            const filteredItems = items.filter(
                (item) => item.ObjectName === "combined.mp4"
            );
            if (filteredItems.length > 0) {
                const videoUrl = `${urlbase}${videoName}/${filteredItems[0].ObjectName}`;
                console.log("Video URL", videoUrl);
                if (video_url !== videoUrl) {
                    setVideoUrl(videoUrl);
                }
            }

            // Verificar si hay algún ObjectName que comience por "video_con_texto"
            // const hasVideoConTexto = items.some((item) =>
            //     item.ObjectName.startsWith("video_con_texto")
            // );
            // setIsDisabled(hasVideoConTexto);
        }
    }, [items, urlbase, videoName, setVideoUrl]);

    useEffect(() => {
        items.forEach((item, index) => {
            if (videoRefs.current[index]) {
                const videoUrl = `${urlbase}${videoName}/${item.ObjectName}`;
                const player = videojs(videoRefs.current[index], {
                    controls: true,
                    autoplay: false,
                    preload: "auto",
                    muted: true,
                    sources: [
                        {
                            src: videoUrl,
                            type: "video/mp4",
                        },
                    ],
                });

                player.on("play", () => {
                    player.muted(false);
                });

                playersRef.current.push(player);

                return () => {
                    playersRef.current.forEach(player => {
                        if (player) {
                            player.dispose();
                        }
                    });
                    playersRef.current = [];
                };
            }
        });
    }, [items, urlbase, videoName]);

    const [contactData, setContactData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [responseOrder, setResponseOrder] = useState(null);
    const [urlKey, setUrlKey] = useState(null);
    const [loopDuration, setLoopDuration] = useState(0);

    useEffect(() => {
        const fetchContactData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/videos/create_contacto/?conference_id=${videoName}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch video data");
                }
                const data = await response.json();
                setContactData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const fetchResponseOrder = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/videos/video-responses-order?room_code=${videoName}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch video data");
                }
                const data = await response.json();
                console.log("Response order", response);
                setResponseOrder(data);
            } catch (error) {
                setError(error.message);
            }
        };

        const fetchUrlKey = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/videos/video-generation-queue-items?queue_id=${queueId}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch video data");
                }
                const data = await response.json();
                setUrlKey(data);
            } catch (error) {
                setError(error.message);
            }
        };

        const fetchLoopDuration = async (id) => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/videos/get-duration-by-queue-id`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ id }),
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch video data");
                }
                const data = await response.json();
                setLoopDuration(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchContactData();
        fetchResponseOrder();
        fetchUrlKey();
        fetchLoopDuration(queueId);
    }, [videoName]);

    const [texts, setTexts] = useState(null);

    useEffect(() => {
        if (responseOrder && urlKey && loopDuration) {
            console.log("Orden de respuestas", responseOrder);
            console.log("Urls keys", urlKey);
            console.log("Duración del loop", loopDuration);

            // Agrupar respuestas por video_index y sumar las duraciones
            const groupedResponses = responseOrder.reduce((acc, response) => {
                if (!acc[response.video_index]) {
                    acc[response.video_index] = 0;
                }
                acc[response.video_index] += response.response_duration;
                return acc;
            }, {});

            // console.log("Duraciones agrupadas por video_index", groupedResponses);

            // Calcular los puntos de pausa entre preguntas
            const pausePoints = Object.values(groupedResponses).reduce(
                (acc, duration, index, array) => {
                    if (index === 0) {
                        acc.push(duration);
                    } else {
                        acc.push(acc[index - 1] + duration);
                    }
                    return acc;
                },
                []
            );

            // console.log("Puntos de pausa entre preguntas", pausePoints);

            // Convertir los elementos de pausePoints en elementos separados llamados texts
            const texts = pausePoints.map((point, index) => {
                let start_time;
                if (index === 0) {
                    start_time = 0;
                } else {
                    start_time = pausePoints[index - 1] - 4 + 4;
                }
                const urlKeyItem = urlKey[index];
                return {
                    text: urlKeyItem ? urlKeyItem.keyQuestion : `Pregunta ${index + 1}`,
                    start_time,
                    duration: 4,
                    audio: urlKeyItem ? urlKeyItem.keyQuestionUrl : "",
                    questionSegundos: urlKeyItem ? urlKeyItem.keyQuestionSegundos : 0,
                };
            });
            // console.log("Texts con start_time, audio y questionSegundos", texts);
            setTexts(texts);
        }
    }, [responseOrder, urlKey, loopDuration]);

    const handleDownload = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = url.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const extractValues = (str) => {
        const match = str.match(/(.*?)-input=(.*)/);
        if (match) {
            return { original: match[2], value: match[1] };
        }
        return { original: str, value: null };
    };

    const contactTextRef = useRef(null);
    const [contact_text, setContactText] = useState(null);

    useEffect(() => {
        if (texts && video_url && contactTextRef.current) {
            // console.log("00 texts", texts);
            // console.log("00 video_url", video_url);
            setContactText(contactTextRef.current);
            // console.log("00 contact_text", contact_text);
        }
    }, [texts, video_url, contactTextRef]);

    const handleEditVideo = async () => {
        if (!texts || texts.length === 0) {
            toast.error("There is an error with the answers, recording them or edit them", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        
        toast("🦄 The video is being edited...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/videos/edit-video`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ texts, video_url, contact_text }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to edit video");
            }
            const data = await response.json();
            console.log("Edit video response:", data);
        } catch (error) {
            console.error("Error editing video:", error);
            // toast.error("There was an error editing the video.", {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: false,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
        }
    };

    const parseSRT = (srtText) => {
        const srtBlocks = srtText.trim().split(/\n(?=\d+\n\d{2}:\d{2}:\d{2},\d{3} --> \d{2}:\d{2}:\d{2},\d{3}\n)/);
        const srtArray = srtBlocks.map((block) => {
            const [id, time, ...textLines] = block.split("\n");
            const [startTime, endTime] = time.split(" --> ");
            const startSeconds = convertToSeconds(startTime);
            const endSeconds = convertToSeconds(endTime);
            const text = textLines.join(" ");

            return {
                id,
                startTime,
                startSeconds,
                endTime,
                endSeconds,
                text,
            };
        });
        return srtArray;
    };

    const convertToSeconds = (time) => {
        const [hours, minutes, seconds] = time.split(":");
        const [secs, millis] = seconds.split(",");
        return (
            parseInt(hours) * 3600 +
            parseInt(minutes) * 60 +
            parseFloat(`${secs}.${millis}`)
        );
    };


    const [subtitles, setSubtitles] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchSubtitleText = async () => {
            if (!subtitleUrl) return;

            try {
                const response = await fetch(`${backendUrl}/videos/get-subtitle-text`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ url: subtitleUrl }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                const srtText = data.subtitle_text;
                const srtArray = parseSRT(srtText);
                setSubtitles(srtArray);

            } catch (error) {
                console.error('Error fetching subtitle text:', error);
            }
        };

        fetchSubtitleText();
    }, [subtitleUrl]);


    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCloseModalRespuestas = () => {
        setItems([]); // Limpiar el estado de los items al cerrar el modal
        playersRef.current.forEach(player => {
            if (player) {
                player.dispose();
            }
        });
        playersRef.current = [];
        onClose(); // Llamar a la función onClose pasada como prop
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center overflow-auto">
            <div className="bg-white p-4 rounded-lg shadow-lg w-3/4 max-h-full overflow-y-auto relative">
                <h2 className="text-xl mb-4">Conference {videoName}</h2>
                <div className="absolute top-4 right-4 flex flex-col space-y-2">
                    <button className="text-gray-400 hover:text-gray-500 px-2 py-1 rounded flex justify-end h-12" onClick={handleCloseModalRespuestas}>
                        <FaTimes className='h-8' />
                    </button>
                </div>
                {Array.isArray(contactData) &&
                    contactData.map((contact) => {
                        const companyValues = extractValues(contact.company);
                        const occupationValues = extractValues(contact.occupation);

                        const contact_text = `${contact.name}, ${companyValues.value || companyValues.original
                            }, ${occupationValues.value || occupationValues.original}`;
                        contactTextRef.current = contact_text;

                        return (
                            <div
                                key={contact.id}
                                className="mb-4 flex justify-between items-start"
                            >
                                <div className="text-left">
                                    <p>
                                        <strong>Name:</strong> {contact.name}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {contact.email}
                                    </p>
                                    <p>
                                        <strong>
                                            {companyValues.value
                                                ? companyValues.original + ":"
                                                : "Company:"}
                                        </strong>{" "}
                                        {companyValues.value || companyValues.original}
                                    </p>
                                    <p>
                                        <strong>
                                            {occupationValues.value
                                                ? occupationValues.original + ":"
                                                : "Position:"}
                                        </strong>{" "}
                                        {occupationValues.value || occupationValues.original}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {items.map((item, index) => (
                        <div key={item.id} className="border p-4 rounded-lg">
                            {item.ObjectName === "subtitles.srt" ? (
                                <div className="h-8">
                                    <h2>
                                        <strong>Subtitles</strong>
                                    </h2>
                                </div>
                            ) : item.ObjectName.startsWith("video_con_texto") ? (
                                <div className="h-8">
                                    <h2>
                                        <strong>Edited video</strong>
                                    </h2>
                                </div>
                            ) : item.ObjectName === "combined.mp4" ? (
                                <div className="h-8">
                                    <h2>
                                        <strong>Combined responses</strong>
                                    </h2>
                                </div>
                            ) : (
                                <div className="h-8">
                                    <h2>
                                        <strong>Response {index + 1}</strong>
                                    </h2>
                                </div>
                            )}
                            <p className="overflow-hidden truncate whitespace-nowrap">
                                {item.videoText}
                            </p>
                            {item.ObjectName === "subtitles.srt" ? (
                                <div>
                                    <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded" onClick={handleOpenModal}>
                                        Show Subtitles
                                    </button>
                                    {showModal && <SubtitleEditor subtitles={subtitles} subtitleUrl={subtitleUrl} onClose={handleCloseModal} />}
                                </div>
                            ) : item.ObjectName ? (
                                <div className={`h-80 ${showModal ? 'hidden' : ''}`}>
                                    <div data-vjs-player>
                                        <video
                                            ref={(el) => (videoRefs.current[index] = el)}
                                            className="video-js vjs-default-skin w-full h-full object-cover"
                                            playsInline
                                        />
                                    </div>
                                </div>
                            ) : (
                                <p>Generando video</p>
                            )}
                            <div className="flex justify-center">
                                {item.ObjectName === "combined.mp4" && (
                                    <div>
                                        <button
                                            className={`flex items-center justify-center mt-2 px-4 py-4 rounded mr-2 ${isDisabled
                                                ? "bg-gray-500 cursor-not-allowed"
                                                : "bg-red-500"
                                                } text-white`}
                                            onClick={handleEditVideo}
                                            // disabled={isDisabled}
                                        >
                                            Edit Video
                                        </button>
                                    </div>
                                )}
                                <button
                                    onClick={() =>
                                        handleDownload(`${urlbase}${videoName}/${item.ObjectName}`)
                                    }
                                    className="flex items-center justify-center mt-2 px-4 py-4 rounded bg-blue-500 text-white"
                                >
                                    <svg
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12ZM12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V12.1893L14.4697 10.4697C14.7626 10.1768 15.2374 10.1768 15.5303 10.4697C15.8232 10.7626 15.8232 11.2374 15.5303 11.5303L12.5303 14.5303C12.3897 14.671 12.1989 14.75 12 14.75C11.8011 14.75 11.6103 14.671 11.4697 14.5303L8.46967 11.5303C8.17678 11.2374 8.17678 10.7626 8.46967 10.4697C8.76256 10.1768 9.23744 10.1768 9.53033 10.4697L11.25 12.1893V7C11.25 6.58579 11.5858 6.25 12 6.25ZM8 16.25C7.58579 16.25 7.25 16.5858 7.25 17C7.25 17.4142 7.58579 17.75 8 17.75H16C16.4142 17.75 16.75 17.4142 16.75 17C16.75 16.5858 16.4142 16.25 16 16.25H8Z"
                                            fill="#fff"
                                        ></path>
                                    </svg>
                                    {/* <span className="ml-1">Download</span> */}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default VideoModal;
