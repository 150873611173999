import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';

const Waveform = forwardRef(({
    silentSeconds,
    setSilentSeconds,
    isSpeaking,
    setIsSpeaking,
    audioStarted,
    setAudioStarted,
    setIsMicrophoneActive,
    isMicrophoneActive,
    loop,
    selectedMicrophone,
    efectoCallEnd }, ref) => {
    const canvasRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const dataArrayRef = useRef(null);
    const animationIdRef = useRef(null);
    const streamRef = useRef(null);
    //const [isSpeaking, setIsSpeaking] = useState(false);
    const [micActive, setMicActive] = useState(true);
    const toleranceThreshold = 0.2; // Ajusta este valor según sea necesario

    const setupAudio = async () => {
        try {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            audioContextRef.current = audioContext;
            analyserRef.current = analyser;
            dataArrayRef.current = dataArray;

            const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedMicrophone } });
            streamRef.current = stream;
            const source = audioContext.createMediaStreamSource(stream);
            source.connect(analyser);

            setAudioStarted(true);
            // draw();
        } catch (err) {
            if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
                console.error("Permisos denegados para el micrófono: ", err);

            } else {
                console.error("Error al configurar el audio: ", err);
            }
        }
    };

    useEffect(() => {
        console.log("isMicrophoneActive valor", isMicrophoneActive);
        // setupAudio();
        // setIsMicrophoneActive(true);
        if (!isMicrophoneActive) {
            stopAudio();
        } else {
            setupAudio();
        }
    }, [isMicrophoneActive, selectedMicrophone]);

    const stopAudio = () => {
        if (streamRef.current) {
            console.log("stopAudio");
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
            //setAudioStarted(false);
        }
    };

    const toggleMic = () => {
        console.log("hice click en toggleMic", micActive);
        if (micActive) {
            stopAudio();
        } else {
            setupAudio();
        }
        setMicActive(!micActive);
    };

    const handletoggleMic = () => {
        toggleMic();
        console.log('toggleMic');
    };

    // const draw = () => {
    //     const canvas = canvasRef.current;
    //     if (!canvas) return;
    //     const canvasCtx = canvas.getContext('2d');
    //     const analyser = analyserRef.current;
    //     const dataArray = dataArrayRef.current;

    //     if (!analyser || !dataArray) {
    //         console.error("Analyser or dataArray is null");
    //         return;
    //     }

    //     analyser.getByteTimeDomainData(dataArray);

    //     // Usar color de fondo transparente
    //     canvasCtx.fillStyle = 'rgb(40, 41, 43)'; // Fondo transparente
    //     canvasCtx.fillRect(0, 0, canvas.width, canvas.height); // Rectángulo invisible

    //     canvasCtx.lineWidth = 2;
    //     canvasCtx.strokeStyle = 'rgb(68, 142, 254)';

    //     canvasCtx.beginPath();

    //     const sliceWidth = (canvas.width * 1.0) / analyser.fftSize;
    //     let x = 0;
    //     let isSpeakingNow = false;

    //     for (let i = 0; i < analyser.fftSize; i++) {
    //         const v = dataArray[i] / 128.0;
    //         const y = (v * canvas.height) / 2;

    //         if (v > 1.0 + toleranceThreshold || v < 1.0 - toleranceThreshold) {
    //             isSpeakingNow = true;
    //         }

    //         if (i === 0) {
    //             canvasCtx.moveTo(x, y);
    //         } else {
    //             canvasCtx.lineTo(x, y);
    //         }

    //         x += sliceWidth;
    //     }
    //     setIsSpeaking(isSpeakingNow);

    //     canvasCtx.lineTo(canvas.width, canvas.height / 2);
    //     canvasCtx.stroke();

    //     animationIdRef.current = requestAnimationFrame(draw);
    // };

    const [totalSpeakingTime, setTotalSpeakingTime] = useState(0);

    useEffect(() => {
        if (isSpeaking) {
            setTotalSpeakingTime(prev => prev + 1);
        }
    }, [isSpeaking]);

    useEffect(() => {
        console.log("totalSpeakingTime", totalSpeakingTime);
    }, [totalSpeakingTime]);

    useEffect(() => {
        if (audioStarted && totalSpeakingTime >= 10) {
            let intervalId;
            if (!isSpeaking && loop) {
                intervalId = setInterval(() => {
                    setSilentSeconds(prev => Math.min(prev + 1, 4));
                    console.log("silentSeconds", silentSeconds);
                }, 1000);
            } else {
                setSilentSeconds(0);
            }

            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }
    }, [isSpeaking, audioStarted, loop, totalSpeakingTime]);

    useEffect(() => {
        if (silentSeconds === 4) {
            setTotalSpeakingTime(0); // Reiniciar el estado del conteo de isSpeaking para siguientes respuestas
        }
    }, [silentSeconds]);

    useImperativeHandle(ref, () => ({
        startToggleMic: handletoggleMic,
    }));




    // Inicializa los controles del grabador usando el hook
    const recorderControls = useVoiceVisualizer();
    const { recordedBlob, error, startRecording, stopRecording, audioData } = recorderControls;

    // Obtén el blob de audio grabado
    useEffect(() => {
        try {
            if (!recordedBlob) return;
            console.log(recordedBlob);
        } catch (err) {
            console.error("Error handling recordedBlob:", err);
        }
    }, [recordedBlob, error]);

    // Obtén el error cuando ocurra
    useEffect(() => {
        try {
            if (!error) return;
            console.error(error);
        } catch (err) {
            console.error("Error handling error:", err);
        }
    }, [error]);

    // Inicia la grabación automáticamente cuando el componente se monte
    useEffect(() => {
        try {
        startRecording();
        }
        catch (err) {
            console.error("Error al iniciar la grabación:", err);
        }
    }, [startRecording]);

    // Detiene la grabación cuando efectoCallEnd es true
    useEffect(() => {
        try {
            if (efectoCallEnd) {
                stopRecording();
            }
        } catch (err) {
            console.error("Error al detener la grabación:", err);
        }
    }, [efectoCallEnd, stopRecording]);

    // Monitorea el audioData para actualizar isSpeaking
    useEffect(() => {
        try {
            if (!audioData) return;

            let isSpeakingNow = false;
            for (let i = 0; i < audioData.length; i++) {
                const v = audioData[i] / 128.0;
                if (v > 1.0 + toleranceThreshold || v < 1.0 - toleranceThreshold) {
                    isSpeakingNow = true;
                    break;
                }
            }
            setIsSpeaking(isSpeakingNow);
        } catch (err) {
            console.error("Error analyzing audio data:", err);
        }
    }, [audioData]);

    return (
        <>
            {/* <canvas ref={canvasRef} className='absolute w-40 h-10 rounded-md text-white' onClick={toggleMic} /> */}
            {!efectoCallEnd && (
                <div className='absolute w-40 h-10 rounded-md'>
                        <VoiceVisualizer
                            controls={recorderControls}
                            width={160}
                            height={40}
                            barWidth={3}
                            backgroundColor="#28292b"
                            mainBarColor="#3f83f8"
                            secondaryBarColor="#3f83f8"
                            onlyRecording={false} // Mostrar la visualización siempre
                            isControlPanelShown={false} // Mostrar el panel de control
                            fullscreen={true}
                            speed={2}
                        />
                </div>
            )}
        </>
    );
});

export default Waveform;