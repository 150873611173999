import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const WebSocketComponent = () => {
    const [userId, setUserId] = useState(null);
    const socketRef = useRef(null);
    const reconnectIntervalRef = useRef(null);

    const getUserIdFromToken = async (token) => {
        try {
            const response = await fetch(`${backendUrl}/company/api/get-user-id/`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setUserId(data.user_id);
            console.log('User ID:', data.user_id);
        } catch (error) {
            console.error('Error fetching user ID:', error.message);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            getUserIdFromToken(token);
        } else {
            console.error('No token found in localStorage');
        }
    }, []);

    useEffect(() => {
        if (!userId) {
            console.error('User ID is null, skipping WebSocket connection');
            return;
        }

        const socketUrl = `${backendUrl.replace(/^http/, 'ws')}/ws/video-edit/${userId}/`;
        console.log('Connecting to WebSocket at:', socketUrl);

        const connectWebSocket = () => {
            if (socketRef.current) {
                console.log('WebSocket already connected, skipping new connection');
                return;
            }

            socketRef.current = new WebSocket(socketUrl);

            socketRef.current.onopen = () => {
                console.log('WebSocket connection established');
                if (reconnectIntervalRef.current) {
                    clearInterval(reconnectIntervalRef.current);
                    reconnectIntervalRef.current = null;
                }
            };

            socketRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('WebSocket message received:', data);
                if (data.status === 'completed') {
                    toast(<a href={data.videoUrl} target="_blank" rel="noopener noreferrer">🦄 The video is ready. Click here.</a>, {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            };

            socketRef.current.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            socketRef.current.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
                if (!event.wasClean) {
                    console.error(`WebSocket closed unexpectedly with code: ${event.code}, reason: ${event.reason}`);
                    if (!reconnectIntervalRef.current) {
                        reconnectIntervalRef.current = setInterval(() => {
                            console.log('Attempting to reconnect WebSocket...');
                            connectWebSocket();
                        }, 5000); // Intentar reconectar cada 5 segundos
                    }
                }
                socketRef.current = null;
            };
        };

        connectWebSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
            if (reconnectIntervalRef.current) {
                clearInterval(reconnectIntervalRef.current);
            }
        };
    }, [userId]);

    return null;
};

export default WebSocketComponent;