import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import LoadingScreen from '../components/conferencia/LoadingScreen';
import TermsAndConditions from '../components/conferencia/TermsAndConditions';
import CameraConfig from '../components/conferencia/CameraConfig';
import GoodbyeMessage from '../components/conferencia/GoodbyeMessage';
import VideoSection from '../components/conferencia/VideoSection';
// import { set } from 'video.js/dist/types/tech/middleware';

import ErrorBoundary from '../components/conferencia/ErrorBoundary';


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const getiPhoneVersion = () => {
    const match = navigator.userAgent.match(/iPhone OS (\d+)_/);
    return match ? parseInt(match[1], 10) : null;
};
function VideoApp() {

    //camara de conferencia
    const webcamRef = useRef(null);
    //grabacion de video
    const mediaRecorderRef = useRef(null);
    //video de loop
    const [isLoading, setIsLoading] = useState(true);
    //CAMARA-----------------------------------------
    const [camaraFrontalTracera, setCamaraFrontalTracera] = useState("user");
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [isCameraOn, setIsCameraOn] = useState(true);
    const [tienePermisosCamara, setTienePermisosCamara] = useState(false);
    //captura de video
    const [numeroDeVideo, setNumeroDeVideo] = useState(0)

    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    //subir video
    const [videoResponseId, setVideoResponseId] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    //END CAMARA-------------------------------------
    //Microfono
    const waveformRef = useRef(null);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [silentSeconds, setSilentSeconds] = useState(0);
    const [audioStarted, setAudioStarted] = useState(false);
    const [isMicrophoneActive, setIsMicrophoneActive] = useState(true);
    //TERMS AND CONDITIONS
    const [isChecked, setIsChecked] = useState(false);
    //XXXXXXXXXXXX

    //END TERMS AND CONDITIONS

    //MENSAJE DE DESPEDIDA
    const [respuestaFinal, SetRespuestaFinal] = useState(false);
    //END MENSAJE DE DESPEDIDA

    //PAGINAS
    const [configCameraDone, setConfigCameraDone] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    //END PAGINAS
    //videos y grabaciones

    const [inicioReproduccion, SetInicioReproduccion] = useState(false);

    const [path, setPath] = useState("");
    const location = useLocation();
    const [videoId, setVideoId] = useState(null);
    const [mimeType, setMimeType] = useState("video/webm");

    const createVideoResponse = async () => {
        try {
            const response = await axios.post(`${backendUrl}/videos/create-video-response/`, {
                video: videoId,  // Reemplaza con el ID del VideoGenerationQueue correspondiente
                url: 'http://example.com/video.mp4',
                status: false
            });
            setVideoResponseId(response.data.id);
            return (response.data.id);
        } catch (error) {
            console.error('Error creating VideoResponse:', error);
        }
    };

    const [shouldUpload, setShouldUpload] = useState(false);

    const generateRandomCode = () => {
        const segments = [];
        const characters = 'abcdefghijklmnopqrstuvwxyz';
        for (let i = 0; i < 3; i++) {
            let segment = '';
            for (let j = 0; j < 3; j++) {
                segment += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            segments.push(segment);
        }
        const timestamp = Date.now();
        return `${segments.join('-')}-${timestamp}`;
    };

    const [randomCode, setRandomCode] = useState('');

    useEffect(() => {
        // Generar el código único una sola vez cuando la página carga
        setRandomCode(generateRandomCode());
    }, []);

    //? XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //todo XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //* TODO LO REFERENTE A LA REPRODUCCION DEL VIDEO Y AUDIO
    //todo XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //? XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

    const [videoCombinadoUrl, setVideoCombinadoUrl] = useState("");
    const [audioCombinadoUrl, setAudioCombinadoUrl] = useState("");
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [allVideosPlayed, setAllVideosPlayed] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isVideoSecoundSet, setIsVideoSecoundSet] = useState(false);
    const [loop, setLoop] = useState(false);
    // const [attemptCount, setAttemptCount] = useState(0);

    const [startTimes, setStartTimes] = useState([]);
    const [endTimes, setEndTimes] = useState([]);
    const [startLoop, setStartLoop] = useState(0);
    const [endLoop, setEndLoop] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userId, setUserId] = useState(null);
    const [isPlatinum, setIsPlatinum] = useState(false);
    const [videoName, setVideoName] = useState("");
    const [language, setLanguage] = useState(null);
    const [personalizedGreeting, setPersonalizedGreeting] = useState(null);
    const [personalizedCompany, setPersonalizedCompany] = useState(null);
    const [personalizedPosition, setPersonalizedPosition] = useState(null);
    const [personalizedThankYou, setPersonalizedThankYou] = useState(null);
    const [personalizedUrl, setPersonalizedUrl] = useState(null);
    const [countdown, setCountdown] = useState(null);

    useEffect(() => {
        const fetchVideoQueues = async () => {
            try {
                const response = await fetch(`${backendUrl}/videos/app/viedo-url?customeURL=${path}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log(data);
                setVideoCombinadoUrl(data[0].urlVideoCombinado);
                setAudioCombinadoUrl(data[0].urlAudioCombinado);
                setIsPlatinum(data[0].platinum);
                setVideoName(data[0].videoName);
                setLanguage(data[0].language);
                setPersonalizedGreeting(data[0].personalizedGreeting);
                setPersonalizedCompany(data[0].personalizedCompany);
                setPersonalizedPosition(data[0].personalizedPosition);
                setPersonalizedThankYou(data[0].personalizedThankYou);
                setPersonalizedUrl(data[0].personalizedUrl);


                // Solo ejecutar setVideoSecound si la bandera es false
                if (!isVideoSecoundSet) {
                    const segundosLoop = data[0].replicId[0].duracionSegundosLoop;
                    const segundosItems = data[0].items.map(item => item.segundos);
                    console.log("Segundos Loop:", segundosLoop);
                    console.log("Segundos Items:", segundosItems);
                    const combinedArray = [];
                    segundosItems.forEach(item => {
                        combinedArray.push(item);
                        combinedArray.push(segundosLoop);
                    });
                    console.log("Combined Array:", combinedArray);

                    const startTimes = [];
                    let currentTime = 0;

                    segundosItems.forEach(item => {
                        startTimes.push(currentTime);
                        currentTime += item + segundosLoop;
                    });
                    console.log('Tiempos de inicio:', startTimes);
                    setStartTimes(startTimes);

                    // Calcular los tiempos de finalización de cada pregunta
                    const endTimes = startTimes.map((startTime, index) => startTime + segundosItems[index]);
                    console.log('Tiempos de finalización:', endTimes);
                    setEndTimes(endTimes);

                    // Calcular la duración total del video
                    const totalDuration = combinedArray.reduce((acc, val) => acc + val, 0);

                    const loopStart = totalDuration - 4;
                    setStartLoop(loopStart);

                    const loopEnd = totalDuration - 1;
                    setEndLoop(loopEnd);

                    console.log('Duración total:', totalDuration);
                    console.log('Loop comienza en:', loopStart);
                    console.log('Loop termina en:', loopEnd);

                    setIsVideoSecoundSet(true); // Actualizar la bandera a true
                }
                setVideoId(data[0].id);
                setUserId(data[0].user);
            } catch (error) {
                console.log(error);
            }
        };
        fetchVideoQueues();
    }, [path, isVideoSecoundSet]);

    const [translations, setTranslations] = useState([]);

    const fetchTranslations = async (languageId) => {
        try {
            const response = await fetch(
                `${backendUrl}/videos/translations/${languageId}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch translations');
            }

            const data = await response.json();
            setTranslations(data);
        } catch (error) {
            console.error('Error fetching translations:', error);
        }
    };

    useEffect(() => {
        if (language) {
            fetchTranslations(language);
        }
    }, [language]);

    const [AccepTerms1, setAccepTerms1] = useState('');
    const [AccepTerms2, setAccepTerms2] = useState('');
    const [AccepTerms3, setAccepTerms3] = useState('');
    const [AccepTerms4, setAccepTerms4] = useState('');
    const [AccepTerms5, setAccepTerms5] = useState('');
    const [AccepTerms6, setAccepTerms6] = useState('');
    const [AccepTerms7, setAccepTerms7] = useState('');
    const [CamSettings1, setCamSettings1] = useState('');
    const [CamSettings2, setCamSettings2] = useState('');
    const [CamSettings3, setCamSettings3] = useState('');
    const [CamSettings4, setCamSettings4] = useState('');
    const [Call1, setCall1] = useState('');
    const [Call2, setCall2] = useState('');
    const [Form1, setForm1] = useState('');
    const [Form2, setForm2] = useState('');
    const [Form3, setForm3] = useState('');
    const [Form4, setForm4] = useState('');
    const [Form5, setForm5] = useState('');
    const [Form6, setForm6] = useState('');
    const [Form7, setForm7] = useState('');
    const [Form8, setForm8] = useState('');
    const [Goodbye1, setGoodbye1] = useState('');
    const [Goodbye2, setGoodbye2] = useState('');
    const [FormErrors1, setFormErrors1] = useState('');
    const [Call3, setCall3] = useState('');
    const [TyC1, setTyC1] = useState('');
    const [Call4, setCall4] = useState('');

    useEffect(() => {
        if (translations.length > 0) {
            const translatedItems = translations.map(translation => ({
                translation: translation.translation,
                text_context: translation.text_context
            }));

            const stateSetters = {
                2: setAccepTerms1,
                3: setAccepTerms2,
                4: setAccepTerms3,
                5: setAccepTerms4,
                6: setAccepTerms5,
                7: setAccepTerms6,
                8: setAccepTerms7,
                9: setCamSettings1,
                10: setCamSettings2,
                11: setCamSettings3,
                12: setCamSettings4,
                13: setCall1,
                14: setCall2,
                15: setForm1,
                16: setForm2,
                17: setForm3,
                18: setForm4,
                19: setForm5,
                20: setForm6,
                21: setForm7,
                22: setForm8,
                23: setGoodbye1,
                24: setGoodbye2,
                25: setFormErrors1,
                26: setCall3,
                27: setTyC1,
                28: setCall4
            };

            translatedItems.forEach(item => {
                const setState = stateSetters[item.text_context];
                if (setState) {
                    setState(item.translation);
                }
            });
        }
    }, [translations]);

    const handleLoop = () => {
        const video = videoRef.current;
        const audio = audioRef.current;
        if (video.currentTime >= endLoop) {
            video.currentTime = startLoop;
            audio.currentTime = startLoop;
            setLoop(true);
        }
    };

    useEffect(() => {
        if (silentSeconds >= 4) {
                setSePuedeSubir(true);
                handleStopCaptureClick();
                const endTime = new Date().getTime();
                const duration = ((endTime - startTime) / 1000).toFixed(2); // Duración en segundos, redondeada a dos decimales
                setDuration(duration);
                console.log(`Duración de la grabación: ${duration} segundos`);
            }
    }, [silentSeconds]);

    const hasStartedCaptureRef = useRef(false);

    const playVideoFuntion = (index = 0) => {

        // console.log("Tiempos de inicio:", startTimes, "Tiempos de finalización:", endTimes, "Loop comienza en:", startLoop, "Loop termina en:", endLoop);
        const video = videoRef.current;
        const audio = audioRef.current;

        if (!video || !audio) {
            console.error("El video o el audio no están disponibles.");
            return;
        }

        if (index < 0 || index >= startTimes.length) {
            console.error("Índice fuera de los límites:", index);
            return;
        }

        const startTime = startTimes[index];
        const endTime = endTimes[index];

        if (isNaN(startTime) || isNaN(endTime)) {
            console.error("Tiempo de inicio o finalización no válido:", startTime, endTime);
            return;
        }

        video.currentTime = startTime;
        audio.currentTime = startTime;

        video.play().then(() => {
            audio.play().catch(error => {
                console.error("Error al reproducir el audio:", error);
            });
        }).catch(error => {
            console.error("Error al reproducir el video:", error);
        });


        const handleTimeUpdate = () => {
            // Si el tiempo actual del video es mayor o igual al primer tiempo de endTimes
            if (video.currentTime >= endTime) {
                // Configurar el bucle desde loopStart hasta loopEnd
                video.currentTime = startLoop;
                audio.currentTime = startLoop;

                video.addEventListener('timeupdate', handleLoop);

                // Remover el evento handleTimeUpdate después de configurarlo
                video.removeEventListener('timeupdate', handleTimeUpdate);

                setSilentSeconds(0);

                // Si es la última pregunta, establecer allVideosPlayed en true
                if (index === startTimes.length - 1) {
                    setAllVideosPlayed(true);
                }

                setCountdown(60);

                // Detener la grabación antes de comenzar una nueva grabación
                handleStopCaptureClick().then(() => {
                    const endTime = new Date().getTime();
                    const duration = ((endTime - startTime) / 1000).toFixed(2); // Duración en segundos, redondeada a dos decimales
                    setDuration(duration);
                    console.log(`Duración de la grabación: ${duration} segundos`);
                    setSePuedeSubir(true);
                    // Comenzar a grabar después de que el video entre en el bucle
                    handleStartCaptureClick(); // Reiniciar la grabación después de un breve retraso
                    console.log("Comenzando a grabar después de que el video entre en el bucle");
                    hasStartedCaptureRef.current = true;
                });
                    }
        };


        video.addEventListener('timeupdate', handleTimeUpdate);
    };

    const handleNextQuestion = () => {
        setCountdown(null);
        const video = videoRef.current;
        const audio = audioRef.current;

        // Remover el evento handleLoop antes de avanzar a la siguiente pregunta
        video.removeEventListener('timeupdate', handleLoop);
        audio.removeEventListener('timeupdate', handleLoop);

        const nextIndex = currentQuestionIndex + 1;
        setCurrentQuestionIndex(nextIndex);

        if (currentQuestionIndex < startTimes.length - 1) {
            playVideoFuntion(nextIndex);
        }
    };

    useEffect(() => {
        console.log("currentQuestionIndex:",currentQuestionIndex);
    }, [currentQuestionIndex]);

    const [efectoCallEnd, setEfectoCallEnd] = useState(false);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setSilentSeconds(4); // Establecer silentseconds en 4 cuando el contador llegue a 0
        }
    }, [countdown]);

    useEffect(() => {
        if (efectoCallEnd) {
            const audio = new Audio(process.env.PUBLIC_URL + '/end.mp3');
            const audioAvatar = audioRef.current;
            audioAvatar.pause();
            audioAvatar.src = ''; // Detener la carga del audio
            audioAvatar.load(); // Restablecer el elemento de audio
            audioAvatar.remove(); // Eliminar el elemento de audio del DOM
            audio.play().then(() => {
                // Escuchar el evento 'ended' para saber cuándo el audio ha terminado de reproducirse
                audio.addEventListener('ended', () => {
                    // Establecer respuestaFinal a true después de que el audio se haya reproducido
                    SetRespuestaFinal(true);
                    setIsPlaying(false);
                });
            }).catch(error => {
                console.error("Error al reproducir el audio:", error);
                // Asegurarse de que el estado se actualice incluso si hay un error al reproducir el audio
                SetRespuestaFinal(true);
                setIsPlaying(false);
            });

            handleStopCaptureClick().then(() => {
                const endTime = new Date().getTime();
                const duration = ((endTime - startTime) / 1000).toFixed(2); // Duración en segundos, redondeada a dos decimales
                setDuration(duration);
                console.log(`Duración de la grabación: ${duration} segundos`);
                setSePuedeSubir(true);
            });

        }
    }, [efectoCallEnd]);

    useEffect(() => {
        //si el silencio dura 4 segundos
        if (silentSeconds >= 4 && audioStarted) {
            // si no se esta reproduciento el video y no se ha enviado la respuesta final
            if ((!isPlaying && inicioReproduccion) || (!allVideosPlayed && inicioReproduccion)) {
                // playVideoFuntion();
                handleNextQuestion();
            }
            //si se ha reproducido todos los videos y no se ha enviado la respuesta final
            if (allVideosPlayed && respuestaFinal === false) {
                // Establecer el estado de efectoCallEnd a true
                setEfectoCallEnd(true);
            }
        }
        if (isPlaying) {
            setSilentSeconds(0);
            setAudioStarted(true)
        }
    }, [silentSeconds, isPlaying, audioStarted]);


    //? CODIGO PARA EVITAR QUE SE VUELVA A REPRODUCIR CUANDO SE SALE DE LA PESTAÑA
    useEffect(() => {
        const handleVisibilityChange = () => {
            const audio = audioRef.current;
            const video = videoRef.current;

            if (document.visibilityState === 'hidden') {
                if (audio) {
                    audio.pause();
                }
                if (video) {
                    video.pause();
                }
            } else if (document.visibilityState === 'visible') {
                if (audio) {
                    audio.play().catch(error => {
                        console.error("Error al reproducir el audio:", error);
                    });
                }
                if (video) {
                    video.play().catch(error => {
                        console.error("Error al reproducir el video:", error);
                    });
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    //? XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //todo XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //! FIN
    //todo XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    //? XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

    const [intentosDeSubida, setIntentosDeSubida] = useState(0);
    const [activeUploads, setActiveUploads] = useState(0);
    const [subido, setSubido] = useState(0);

    const handleDownload = useCallback(async (chunks, questionIndex, duration, attempt = 1) => {
        if (chunks.length) {
            setIntentosDeSubida((prevIntentos) => prevIntentos + 1);
            const videoNumber = numeroDeVideo + 1;
            setNumeroDeVideo(videoNumber);
            console.log("Subiendo video:", videoNumber);
            const blob = new Blob(chunks, {
                type: mimeType
            });
            const fileName = `${videoNumber}`;
    
            const saveVideoResponseOrder = async (chunk, chunkFileName) => {
                const responseIndex = parseInt(chunkFileName.split('.')[0], 10); // Convertir el nombre del archivo a entero
    
                const orderData = {
                    room_code: randomCode,
                    video_index: questionIndex, // Usar el questionIndex pasado
                    response_index: responseIndex,
                    response_duration: duration
                };
    
                console.log('Video response order data:', orderData);
    
                const orderResponse = await fetch(`${backendUrl}/videos/video-responses-order`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(orderData)
                });
    
                if (!orderResponse.ok) {
                    throw new Error('Failed to post video response order');
                }
    
                const orderResult = await orderResponse.json();
                console.log('Video response order posted successfully:', orderResult);
            };
    
            const uploadChunk = async (chunk, chunkFileName) => {
                const formData = new FormData();
                formData.append('video', chunk, chunkFileName);
                formData.append('videogenerationqueue_id', videoId);
                formData.append('videoResponse_id', randomCode);
    
                try {
                    const response = await fetch(`${backendUrl}/videos/upload-video/`, {
                        method: 'POST',
                        body: formData,
                    });
                    if (!response.ok) {
                        throw new Error('Failed to upload video chunk');
                    }
                    const result = await response.json();
                    console.log(`Video chunk uploaded successfully: ${chunkFileName}`, result);
                    setSubido(prev => prev + 1);
                } catch (error) {
                    console.error('Error uploading video chunk:', error);
                    if (attempt < 3) { // Reintentar hasta 3 veces
                        console.log(`Reintentando subida (${attempt + 1}/3)`);
                        await handleDownload(chunks, questionIndex, duration, attempt + 1);
                    } else {
                        console.error('Subida fallida después de 3 intentos');
                    }
                }
            };
    
            const chunkFileName = `${fileName}.${mimeType.split('/')[1].split(';')[0]}`;
            try {
                await saveVideoResponseOrder(blob, chunkFileName); // Guardar primero los datos de orden de respuesta del video
                await uploadChunk(blob, chunkFileName); // Luego subir el video
            } catch (error) {
                console.error('Error en el proceso de guardado o subida:', error);
            }
    
        } else {
            console.log("No hay grabaciones");
        }
    }, [numeroDeVideo, mimeType, videoId, randomCode, backendUrl]);

    const [grabando, setGrabando] = useState(false);
    const [duration, setDuration] = useState(0);
    const handleStopCaptureClick = useCallback(() => {
        setGrabando(false);
        return new Promise((resolve) => {
            if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
                mediaRecorderRef.current.addEventListener("dataavailable", ({ data }) => {
                    if (data.size > 0) {
                        console.log("Data available dentro de handleStopCaptureClick:", data);
                        setRecordedChunks((prev) => {
                            const newChunks = prev.concat(data);
                            return newChunks;
                        });
                    }
                    resolve();
                }, { once: true });
                mediaRecorderRef.current.stop();
                console.log("Grabación detenida");
            } else {
                resolve();
            }
            setCapturing(false);
        });
    }, [mediaRecorderRef, setCapturing, setRecordedChunks]);

    const [uploadQueue, setUploadQueue] = useState([]);

    const [sePuedeSubir, setSePuedeSubir] = useState(false);
    const [uploadCount, setUploadCount] = useState(0);
    useEffect(() => {
        if (recordedChunks.length > 0) {
            const uploadItem = {
                chunks: recordedChunks,
                questionIndex: currentQuestionIndex, // Capturar el currentQuestionIndex actual
                duration: duration // Capturar la duración actual
            };
            console.log("Adding to upload queue:", uploadItem);
            setUploadQueue((prevQueue) => [...prevQueue, uploadItem]);
            setRecordedChunks([]);
            setUploadCount((prevCount) => prevCount + 1);
        }
    }, [recordedChunks, currentQuestionIndex, duration]);

    useEffect(() => {
        if (uploadQueue.length > 0 && activeUploads < 1) {
            const { chunks, questionIndex, duration } = uploadQueue[0];
            console.log("Uploading next chunks:", chunks);
            setUploadQueue((prevQueue) => prevQueue.slice(1));
            setActiveUploads((prevActive) => prevActive + 1);
            handleDownload(chunks, questionIndex, duration).then(() => {
                console.log("Upload complete");
                setActiveUploads((prevActive) => prevActive - 1);
            });
        }
    }, [uploadQueue, activeUploads, handleDownload]);

    //Verifica si esta grabando
    const handleDataAvailable = useCallback(
        async ({ data }) => {
            if (data.size > 0) {
                console.log("Data available handleDataAvailable:", data);
                setRecordedChunks((prev) => {
                    const newChunks = prev.concat(data);
                    const totalSize = newChunks.reduce((acc, chunk) => acc + chunk.size, 0);
                    console.log(`Total size: ${totalSize / (1024 * 1024)} MB`);
    
                    return newChunks;
                });
            }
        },
        [setRecordedChunks]
    );

    const timeoutRef = useRef(null);

    const [startTime, setStartTime] = useState(null);

    const handleStartCaptureClick = useCallback(() => {
        setGrabando(true);
        if (webcamRef.current && webcamRef.current.stream) {
            setCapturing(true);
            let options = { 
                mimeType: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
                videoBitsPerSecond: 4000000 };
            // let options = { mimeType: "video/webm" };
    
            // Verificar si el formato es compatible
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                options = { mimeType: "video/webm",
                videoBitsPerSecond: 4000000};
            }
    
            setMimeType(options.mimeType);
    
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, options);
    
            mediaRecorderRef.current.addEventListener(
                "dataavailable",
                handleDataAvailable
            );

            mediaRecorderRef.current.start();

            // Guardar la hora de inicio de la grabación
            const startTime = new Date().getTime();
            setStartTime(startTime);
            console.log("Grabación iniciada", new Date(startTime));
    
            // Cancelar el timeout anterior si existe
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            // Detener la grabación automáticamente después de 40 segundos
            timeoutRef.current = setTimeout(() => {
                handleStopCaptureClick().then(() => {
                    const endTime = new Date().getTime();
                    const duration = ((endTime - startTime) / 1000).toFixed(2); // Duración en segundos, redondeada a dos decimales
                    console.log(`Duración de la grabación: ${duration} segundos`);
                    setDuration(duration);
                    setSePuedeSubir(true);
                    handleStartCaptureClick(); // Reiniciar la grabación después de un breve retraso
                    // handleStartCaptureClick(); // Reiniciar la grabación inmediatamente
                });
            }, 20000); // 20 segundos

        } else {
            console.error("La cámara no está lista");
        }
    }, [webcamRef, setCapturing, handleDataAvailable]);
    
    //END FUNCION PARA LA CREACION DEL VIDEO EN EL SERVIDOR
    //FUNCION PARA OBTENER LOS DISPOSITIVOS DE VIDEO
    const handleDevices = useCallback((mediaDevices) => {
        setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));
    });
    //FUNCION PARA VERIFICAR PERMISOS DE CAMARA
    const checkCameraPermissions = async () => {
        //optienes los permisos de la camara
        const permissionStatus = await navigator.permissions.query({ name: 'camera' });
        //si los permisos cambian, recarga la pagina
        permissionStatus.onchange = () => {
            window.location.reload();
        }
        //si los permisos estan permitidos, esto se utilizara para mostrar el loading
        if (permissionStatus.state === 'granted') {
            setTienePermisosCamara(true);
            //se coloca que ya se aceptaron los terminos y condiciones si ya tiene permisos de la camara
            // setTermsAndConditions(true);
        }
    };

    const [microphones, setMicrophones] = useState([]);
    const [selectedMicrophone, setSelectedMicrophone] = useState('');

    useEffect(() => {
        // Solicitar permisos para el micrófono
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => {
                // Obtener la lista de dispositivos de audio
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    const audioDevices = devices.filter(device => device.kind === 'audioinput');
                    setMicrophones(audioDevices);
                    console.log("Dispositivos de audio:", audioDevices);
                    if (audioDevices.length > 0) {
                        setSelectedMicrophone(audioDevices[0].deviceId); // Seleccionar el primer micrófono por defecto
                    }
                });
            })
            .catch(err => {
                console.error("Error al solicitar permisos para el micrófono: ", err);
                alert("No se pudo acceder al micrófono. Por favor, verifica los permisos.");
            });
    }, []);


    //FUNCION PARA INICIAR EL MICROFONO
    const toggleMicrophone = () => {
        if (waveformRef.current) {
            try {
                console.log("activando microfono", waveformRef.current);
                waveformRef.current.startToggleMic(selectedMicrophone);
                setIsMicrophoneActive(!isMicrophoneActive);
            } catch (err) {
                console.error("Error al alternar el micrófono: ", err);
                alert("Ocurrió un error al alternar el micrófono. Por favor, inténtalo de nuevo.");
            }
        } else {
            console.warn("waveformRef.current es nulo. Asegúrate de que el componente esté montado correctamente.");
            alert("No se pudo acceder al micrófono. Asegúrate de que el componente esté montado correctamente.");
        }
        console.log("microfono", waveformRef.current);
    };

    const startMic = () => {
        if (waveformRef.current) {
            waveformRef.current.startToggleMic(selectedMicrophone);
        }
    };
    //FUNCION PARA COMENZAR A REPRODUCIR LOS VIDEOS Y GRABAR LA CAMARA
    const handleSetConfigCameraDone = () => {
        setConfigCameraDone(true)
        startMic();

        SetInicioReproduccion(true);
    }
    //FUNCIONES PARA GRABAR LA CAMARA

    //Descarga la respuesta
    // useEffect(() => {
    //     const baseUrl = "/app/";
    //     const currentPath = location.pathname;
    //     if (currentPath.startsWith(baseUrl)) {
    //         const extractedPath = currentPath.slice(baseUrl.length);
    //         setPath(extractedPath);
    //     }
    //     //si es video
    //     setIsMobile(window.innerWidth <= 768);

    //     navigator.mediaDevices.enumerateDevices().then(handleDevices);
    //     checkCameraPermissions();
    //     const timer = setTimeout(() => {
    //         setIsLoading(false);
    //     }, 3000);
    //     return () => clearTimeout(timer);
    // }, []);

    useEffect(() => {
        const currentPath = location.pathname;
        const segments = currentPath.split('/').filter(Boolean); // Divide la URL en segmentos y filtra los vacíos
        const extractedPath = segments[segments.length - 1]; // Obtiene el último segmento de la URL
        setPath(extractedPath);

        setIsMobile(window.innerWidth <= 768);

        navigator.mediaDevices.enumerateDevices().then(handleDevices);
        checkCameraPermissions();
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, [location]);
    //MONITOREO DE MICROFONO

    //FUNCION PARA OBTENER LOS DISPOSITIVOS DE VIDEO
    useEffect(() => {
        const getCameras = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const cameras = devices.filter(device => device.kind === 'videoinput');
                if (cameras.length > 0) {
                    setSelectedDevice(cameras[0].deviceId); // Establece el ID de la primera cámara encontrada
                }
            } catch (error) {
                console.error('Error al obtener dispositivos de medios:', error);
            }
        };
        if (!isMobile) {
            getCameras();
        }
    }, [isMobile])

    useEffect(() => {
        if (videoId && !videoResponseId) {
            createVideoResponse();
        }
    }, [videoId]);

    useEffect(() => {
        setAudioStarted(true)
    }, [isSpeaking])

    useEffect(() => {
        console.log(recordedChunks);
    }, [recordedChunks])
    //ENDMONITOREO DE MICROFONO

    const iPhoneVersion = getiPhoneVersion();

    useEffect(() => {
        // Guardar los estilos originales
        const originalBodyStyle = document.body.style.backgroundColor;
        const originalRootStyle = document.getElementById('root').style.backgroundColor;

        // Quitar los estilos de background color
        document.body.style.backgroundColor = '#1b1b1d';
        document.getElementById('root').style.backgroundColor = '#1b1b1d';

        // Limpiar los estilos cuando el componente se desmonta
        return () => {
            document.body.style.backgroundColor = originalBodyStyle;
            document.getElementById('root').style.backgroundColor = originalRootStyle;
        };
    }, []);

    const [isJoin, setIsJoin] = useState(false);

    const handleJoinClick = () => {
        setIsJoin(true);
        handleSetConfigCameraDone();

        setTimeout(() => {
            playVideoFuntion();
            // Quitar el atributo muted y reproducir el video
        }, 500);
    };

    const [isMuted, setIsMuted] = useState(true);


    return (
        <div className="bg-fondo flex flex-col min-h-screen">
            <Helmet>
                <title>Video Conference - Trustreel.AI</title>
            </Helmet>
            {isLoading ? (
                <>
                    {/*LOADING SCREEN*/}
                    <LoadingScreen />
                    {/*END LOADING SCREEN*/}
                </>
            ) : (
                <>
                    {/*TERMS AND CONDITIONS*/}
                    {(termsAndConditions === false && configCameraDone === false) ? (
                        <TermsAndConditions
                            personalizedGreeting={personalizedGreeting}
                            AccepTerms1={AccepTerms1}
                            AccepTerms2={AccepTerms2}
                            AccepTerms3={AccepTerms3}
                            AccepTerms4={AccepTerms4}
                            AccepTerms5={AccepTerms5}
                            AccepTerms6={AccepTerms6}
                            AccepTerms7={AccepTerms7}
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                            setTermsAndConditions={setTermsAndConditions}
                        />
                    ) : (<></>)}
                    {/*END TERMS AND CONDITIONS*/}


                    {/*CONFIG CAMERA*/}
                    {(configCameraDone === false && termsAndConditions === true) ? (
                        <CameraConfig
                            CamSettings1={CamSettings1}
                            CamSettings2={CamSettings2}
                            CamSettings3={CamSettings3}
                            CamSettings4={CamSettings4}
                            isCameraOn={isCameraOn}
                            setIsCameraOn={setIsCameraOn}
                            isMicrophoneActive={isMicrophoneActive}
                            setIsMicrophoneActive={setIsMicrophoneActive}
                            isMobile={isMobile}
                            camaraFrontalTracera={camaraFrontalTracera}
                            setCamaraFrontalTracera={setCamaraFrontalTracera}
                            devices={devices}
                            selectedDevice={selectedDevice}
                            setSelectedDevice={setSelectedDevice}
                            handleJoinClick={handleJoinClick}
                            setSelectedMicrophone={setSelectedMicrophone}
                            selectedMicrophone={selectedMicrophone}
                            microphones={microphones}
                        />
                    ) : (<></>)}
                    {/*END CONFIG CAMERA*/}


                    {/*MENSAJE DE DESPEDIDA*/}
                    {(respuestaFinal === true) ? (
                        <GoodbyeMessage
                        personalizedCompany={personalizedCompany}
                        personalizedPosition={personalizedPosition}
                        personalizedThankYou={personalizedThankYou}
                        personalizedUrl={personalizedUrl}
                        Form1={Form1}
                        Form2={Form2}
                        Form3={Form3}
                        Form4={Form4}
                        Form5={Form5}
                        Form6={Form6}
                        Form7={Form7}
                        Form8={Form8}
                        FormErrors1={FormErrors1}
                        Goodbye1={Goodbye1}
                        Goodbye2={Goodbye2}
                        AccepTerms5={AccepTerms5}
                        AccepTerms6={AccepTerms6}
                        TyC1={TyC1}
                        respuestaFinal={respuestaFinal} 
                        randomCode={randomCode}
                        intentosDeSubida={intentosDeSubida}
                        subido={subido}
                        videoId={videoId}
                        userId={userId}
                        isPlatinum={isPlatinum}
                        videoName={videoName}/>
                    ) : (<></>)}
                    {/*END MENSAJE DE DESPEDIDA*/}
                </>
            )}
            {/*Lo siguiente es para que comience a cargar los videos desde el principio jhonny*/}
            {isJoin && (
                <>
                    {respuestaFinal === "true" ? (<></>) : (
                        <ErrorBoundary>
                            <VideoSection
                                Call1={Call1}
                                Call2={Call2}
                                Call3={Call3}
                                Call4={Call4}
                                videoCombinadoUrl={videoCombinadoUrl}
                                videoRef={videoRef}
                                isMuted={isMuted}
                                efectoCallEnd={efectoCallEnd}
                                audioRef={audioRef}
                                audioCombinadoUrl={audioCombinadoUrl}
                                configCameraDone={configCameraDone}
                                webcamRef={webcamRef}
                                camaraFrontalTracera={camaraFrontalTracera}
                                selectedDevice={selectedDevice}
                                isMicrophoneActive={isMicrophoneActive}
                                toggleMicrophone={toggleMicrophone}
                                isSpeaking={isSpeaking}
                                setIsSpeaking={setIsSpeaking}
                                silentSeconds={silentSeconds}
                                setSilentSeconds={setSilentSeconds}
                                audioStarted={audioStarted}
                                setAudioStarted={setAudioStarted}
                                setIsMicrophoneActive={setIsMicrophoneActive}
                                waveformRef={waveformRef}
                                respuestaFinal={respuestaFinal}
                                handleNextQuestion={handleNextQuestion}
                                loop={loop}
                                grabando={grabando}
                                intentosDeSubida={intentosDeSubida}
                                subido={subido}
                                selectedMicrophone={selectedMicrophone}
                                setSelectedMicrophone={setSelectedMicrophone}
                                countdown={countdown}
                                setCountdown={setCountdown}
                            />
                        </ErrorBoundary>
                    )}
                </>
            )}
                    {/* {uploadCount > 0 && (
                        
                        <div className='flex items-center justify-center col-span-1'>
                            <p className='text-white'>Partes en cola: {uploadCount}</p>
                        </div>
                    )}
                    {intentosDeSubida > 0 && (
                        <div className='flex items-center justify-center col-span-1'>
                            <p className='text-white'>Intentos de subida: {intentosDeSubida}</p>
                        </div>
                    )}
                    {subido > 0 && (
                        <div className='flex items-center justify-center col-span-1'>
                            <p className='text-white'>Partes subidas: {subido}</p>
                        </div>
                    )} */}
        </div >
    );
}

export default VideoApp;